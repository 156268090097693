<template>
    <!-- 账户余额 -->
    <div>
        <!-- 账户余额 -->
        <center-wallet-top></center-wallet-top>
        <!-- 中间标签页部分 -->
        <order-tabs
            v-model="tab"
            :show-title="false"
            :tabs-list="tabList"
            @tab-click="getData">
        </order-tabs>
        <order-search
            :start="startDate"
            :end="endDate"
            @handle-start="handleStart"
            @handle-end="handleEnd"
            @handle-search="getData">
        </order-search>
        <!-- 表格 -->
        <el-table
            class="wallet-table"
            :data="wallet_list">
            <!-- 交易日期 -->
            <el-table-column
                label="交易日期"
                prop="ordertime">
            </el-table-column>
            <!-- 交易类型 -->
            <el-table-column
                label="交易类型"
                prop="type">
            </el-table-column>
            <!-- 金额 -->
            <el-table-column
                label="金额"
                prop="money">
            </el-table-column>
            <!-- 状态 -->
            <el-table-column
                label="状态"
                prop="status">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import order from '@/mixins/centerOrder'

export default {
    mixins: [ order ],
    data() {
        return {
            tabList: [
                {
                    value: '-2',
                    label: '全部'
                },
                {
                    value: '1',
                    label: '支出明细'
                },
                {
                    value: '2',
                    label: '收入明细'
                }
            ]
        }
    },
    computed: mapState({
        user_id: state => state.user.user_id,
        wallet_list: state => state.user.wallet_list
    }),
    components: {
        centerWalletTop: () => import('@/components/centerWalletTop'),
    },
    methods: {
        ...mapMutations([
            'setWalletList'
        ]),
        ...mapActions([
            'getWalletList'
        ]),
        getParams() {
			console.log(this.startDate,this.endDate)
            let userID = this.user_id,
                fromTime = this.startDate,
                toTime = this.endDate,
                type = this.tab == '-2'? '0': this.tab;
            return this.$qs.stringify({
                userID,
                fromTime,
                toTime,
                type
            })
        },
        getData() {
            let url = 'usercenter/getbill',
                params = this.getParams();
            this.getWalletList({
                url,
                params
            })
        },
        handleChange(){
            let fromTime = sessionStorage.getItem("fromTime") || "",
            toTime = sessionStorage.getItem("toTime") || "";
            this.getWalletList({
                fromTime,
                toTime
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        // ...
        next(vm => {
            vm.getData();
        })
    },
    beforeRouteLeave (to, from, next) {
        this.setWalletList([]);
        next();
    }
}
</script>

<style scoped>
/* 表格 */
.wallet-table{
    margin-top: 20px;
    border-top: 1px solid #F2F6FC;
}
</style>
